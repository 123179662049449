import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'bootstrap/scss/bootstrap.scss'
import 'bootstrap'

import auth from './services/auth'

// const urlParams = new URLSearchParams(window.location.search);
// const ebayCode = urlParams.get('code');
// if (ebayCode) {
//   store.commit('eshop/setEbayCode', ebayCode);
//   window.location.search = null
// }



createApp(App)
  .use(auth)
  .use(store)
  .use(router)
  .mount('#app')
