<template>
  <div>
  <transition mode="out-in">
    <div v-if="isLoading" class="container container-container d-flex align-items-center justify-content-center" style="min-height:100svh;">
      <loading-spinner />
    </div>
    <div v-else-if="isAuthenticated">
      <nav-bar />

      <transition mode="out-in">
        <router-view/>
      </transition>
    </div>
    <div v-else class="container container-container d-flex align-items-center justify-content-center" style="min-height:100svh;">
      <login-button />
    </div>
  </transition>
  </div>

</template>

<script setup>
import NavBar from '@/components/NavBar.vue'
import LoginButton from "@/components/LoginButton.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

import { useAuth0 } from "@auth0/auth0-vue"
import { jwtDecode } from "jwt-decode";
import { useStore } from "vuex"
import { watch } from "vue"
const store = useStore()
const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0()

watch(isLoading, (newValue) => {
  if (!newValue && isAuthenticated) {
    loadRoles()
  }
});

async function loadRoles() {
  try {
    const silentToken = await getAccessTokenSilently()
    const token = jwtDecode(silentToken)
    store.commit('setRoles', token.permissions || [])
  } catch (e) {
    console.error(e)
    store.commit('setRoles', [])
  }
}

</script>

<style lang="scss">
html, body {
  overscroll-behavior-x: none;
}
#app-area {min-height: 100svh;}
.container-container { max-width: 900px !important;}
#main-content, #main { min-height: 100svh}
.v-enter-active,
.v-leave-active,
.group-enter-active,
.group-leave-active{
  transition: all 0.3s ease;
}

.v-enter-from,
.v-leave-to,
.group-enter-from,
.group-leave-to {
  opacity: 0;
}

.group-enter-from,
.group-leave-to {
  transform: translateX(30px);
}

.card-grid {
  display: flex;
  flex-wrap: wrap;
  .card {
    width: 350px;
    min-height: 200px;
    max-width: 100%;
    max-height: 100%;
    margin-right: 1rem;
    margin-bottom: 1rem;
    &:last-child { margin-right: 0; }
    .card-body {
      display: flex;
      align-items: center;
      justify-content: center;
      .lead {
        font-size: 3rem;
      }
    }
  }
}
</style>
