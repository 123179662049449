


<template>
  <div>
    <div class="d-flex align-items-center mb-4">
      <h1 class="m-0">Stores</h1>
      <store-create class="ms-auto" />
    </div>

    <transition mode="out-in">
      <div v-if="!eshops">
        <loading-spinner />
      </div>
      <div v-else-if="eshops">
        <store-list />
      </div>
      <loading-spinner v-else />
    </transition>
  </div>
</template>

<script setup>
import StoreList from '@/components/StoreList.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import {useAuth0} from "@auth0/auth0-vue";
import { nextTick, computed } from 'vue'
import { useStore } from 'vuex'
import StoreCreate from "@/components/StoreCreate.vue";

const store = useStore()
const auth0 = useAuth0()
const eshops = computed(() => store.state.eshop.stores)

async function loadStore() {
  await nextTick()
  store.dispatch('eshop/loadStores', auth0)
      .catch(e => {
        alert(e.message)
      })
}

loadStore()


</script>

<style scoped>
.home-content {
  width: 500px; max-width: 100%;
}
</style>
